import React from 'react';
import formatDate from '../../util/formatDate';
import removeDuplicates from '../../util/removeDuplicates';
import api from '../../util/apiRequest';
import hasProp from '../../util/hasProp';
import DisplayRetourcode from '../element/retourcode/DisplayRetourcode';
import isAuthorized from '../../util/isAuthorized';
import {BERICHT_VERSIE, FILTER_DATE_MAX} from '../../configuration';

export default class FormAllocationRequest extends React.Component {
	constructor(props) {
		super(props);

		this.form = React.createRef();

		const data = props.form.data;

		const lists = props.form.data.lists;

		const contracts = props.form.data.contracts;

		let index = lists.gemeentes.findIndex(row => {
			return row.gemeente_code === props.client.gemeente_code;
		});
		let gemeente = index !== -1 ? props.client.gemeente_code : lists.gemeentes[0].gemeente_code;

		const gemeente_code = data.gemeente_code ? data.gemeente_code : gemeente;

		this.state = {
			allocationConflict: null,
			verwijzer: data.verwijzer ? data.verwijzer : '',
			zorgverlenercode: data.zorgverlenercode ? data.zorgverlenercode : '',
			visible: data.zorgverlenercode ? 1 : 2,
			soort: data.soort ? data.soort : lists.COD327[0].code,
			code_visible: true,
			gemeentes: [],
			gemeente_code: gemeente_code,
			contracts: contracts,
			categorie: '',
			categorieList: [],
			product: '',
			productList: [],
			eenheid: '',
			eenheidList: [],
			frequentie: '',
			frequentieList: [],
			begindatum: data.begindatum ? formatDate(data.begindatum, 1) : '',
			einddatum: data.einddatum ? formatDate(data.einddatum, 1) : '',
			aspecifiek: data.product_code === null ? true : false,
			generic: data.categorie_code === null ? true : false,
			next: '',
			beschikking: data.beschikking ?? '',
			ingangsdatum: data.ingangsdatum ? formatDate(data.ingangsdatum, 1) : '',
			updateClients: '',
			budget: 0,
			wet: '',
		};

		const categorieList = this.updateCategorie(contracts, gemeente_code);

		const productList =
					categorieList.length > 0
						? this.updateProduct(contracts, gemeente_code, data.categorie_code ?? categorieList[0].categorie)
						: [];

		const eenheidList =
					productList.length > 0
						? this.updateEenheid(
							contracts,
							gemeente_code,
							data.categorie_code ?? categorieList[0].categorie,
							data.product_code ?? productList[0].product,
						)
						: [];

		const frequentieList =
					eenheidList.length > 0
						? this.updateFrequentie(
							contracts,
							gemeente_code,
							data.categorie_code ?? categorieList[0].categorie,
							data.product_code ?? productList[0].product,
							data.eenheid ?? eenheidList[0].eenheid,
						)
						: [];
		this.state = {
			...this.state,
			gemeentes: this.updateGemeente(),
			categorie: data.categorie_code === null
				? data.wet === 'jw' ? 'Generiek JW' : 'Generiek WMO'
				: data.categorie_code
					? data.categorie_code
					: categorieList.length > 0
						? categorieList[0].categorie
						: '',
			categorieList: categorieList,
			product:
						data.product_code === null
							? 'Aspecifiek'
							: data.product_code
								? data.product_code
								: productList.length > 0
									? productList[0].product
									: '',
			productList: productList,
			eenheid: data.eenheid ? data.eenheid : eenheidList.length > 0 ? eenheidList[0].eenheid : '',
			eenheidList: eenheidList,
			frequentie: `${data.frequentie
				? data.frequentie
				: frequentieList.length > 0
					? frequentieList[0].frequentie
					: ''}`,
			frequentieList: frequentieList,

		};
		this.state = {
			...this.state,
			generic: this.state.categorie === 'Generiek JW' || this.state.categorie === 'Generiek WMO',
			aspecifiek: this.state.product === 'Aspecifiek' || this.state.categorie === 'Generiek JW' || this.state.categorie === 'Generiek WMO',
			wet: this.calculateWet(this.state.categorie),
		};
	}

	handleDisplay = (value, field) => {
		this.setState(state => {
			if (field === 1) {
				state.verwijzer = value;
			} else {
				state.zorgverlenercode = value;
			}

			if (state.code_visible === false) {
				state.visible = 0;
			} else {
				if (state.verwijzer === '' && state.zorgverlenercode === '') {
					state.visible = 3;
				} else if (state.verwijzer !== '' && state.zorgverlenercode === '') {
					state.visible = 2;
				} else if (state.verwijzer === '' && state.zorgverlenercode !== '') {
					state.visible = 1;
				} else {
					state.visible = 0;
				}
			}
			return state;
		});
	};

	handleReset = () => {
		this.props.handleForm({});
	};

	handleCancel = () => {
		this.props.handleForm({});
	};

	handleSubmit = async (event, id) => {
		event.preventDefault();
		event.stopPropagation();

		const formid = this.props.form.formid;
		const {aanbieder, token} = this.props.dbUser;
		const client_id = this.props.form.data.client.id;
		const data = {id: id, aanbieder: aanbieder, formid: formid, client_id: client_id};

		const elements = event.target.elements;
		Object.keys(elements).forEach(key => {
			let element = elements[key];
			data[element.name] = element.value;
		});
		if (data.categorie_code === 'Generiek WMO') {
			data.categorie_code = null;
		}
		if (data.categorie_code === 'Generiek JW') {
			data.categorie_code = null;
		}

		if (formid === 'vot-wijzigen' || formid === 'toewijzing') {
			const updateVot = await api.updateVot(aanbieder, client_id, id, data, api.makeHeader(token));
			if (updateVot.error && updateVot.error === true) {
				this.props.handleForm(updateVot);
				return null;
			}
			if (updateVot.status === 200) {
				data.response = updateVot.response;
				this.props.handleForm({});
				this.props.handleVotTable(data);
			}
		} else if (formid === 'vot-toevoegen') {
			const addVot = await api.addVot(aanbieder, client_id, data, api.makeHeader(token));
			if (addVot.error && addVot.error === true) {
				this.props.handleForm(addVot);
				return null;
			}
			if (addVot.status === 200) {
				data.response = addVot.response;
				if (this.props.form.data.id) {
					// id only exist in old
					await api.archiveVot(aanbieder, client_id, this.props.form.data.id, api.makeHeader(token));
				}
				this.props.handleForm({});
				this.props.handleVotTable(data);
			}
		} else if (formid === 'vot-verwijderen') {
			const archiveVot = await api.archiveVot(aanbieder, client_id, id, api.makeHeader(token));
			if (archiveVot.error && archiveVot.error === true) {
				this.props.handleForm(archiveVot);
				return null;
			}
			if (archiveVot.status === 200) {
				data.response = archiveVot.response;
				this.props.handleForm({});
				this.props.handleVotTable(data);
			}
		}
	};

	handleChangeSoort = value => {
		if (value !== this.state.soort) {
			this.setState(state => {
				state.soort = value;
				state.visible = 0;
				state.code_visible = true;
				state.verwijzer = '';
				state.zorgverlenercode = '';
				if (value === '01' || value === '06' || value === '08') {
					state.code_visible = false;
				}
				return state;
			});
		}
	};

	updateGemeente = () => {
		const keys = ['Gekoppeld aan client',  'Geadviseerd door afwijzing op woonplaats beginsel', 'Laatst ingediend', 'Overige gemeentes'];
		const groupedBy = {};
		for (const row of this.props.form.data.lists.gemeentes) {
			let type;
			if (row.gemeente_code === this.props.client.gemeente_code) {
				type = 'Gekoppeld aan client';
			} else if (row.gemeente_code === this.props.form.data.retour_gemeente) {
				type = 'Geadviseerd door afwijzing op woonplaats beginsel';
			} else if (row.gemeente_code === this.props.form.data.gemeente_code) {
				type = 'Laatst ingediend';
			} else {
				type = 'Overige gemeentes';
			}
			if (!groupedBy[type]) {
				groupedBy[type] = [];
			}
			groupedBy[type].push(row);
		}
		const batched = [];
		for (const key of keys) {
			if (groupedBy[key]) {
				batched.push([key, groupedBy[key]]);
			}
		}
		return batched;
	};

	handleGemeente = value => {
		const {contracts} = this.state;
		const categorieList = this.updateCategorie(contracts, value);
		const productList =
					categorieList.length > 0 ? this.updateProduct(contracts, value, categorieList[0].categorie) : [];
		const eenheidList =
					productList.length > 0
						? this.updateEenheid(contracts, value, categorieList[0].categorie, productList[0].product)
						: [];
		const frequentieList =
					eenheidList.length > 0
						? this.updateFrequentie(
							contracts,
							value,
							categorieList[0].categorie,
							productList[0].product,
							eenheidList[0].eenheid,
						)
						: [];

		this.setState(state => {
			state.gemeente_code = value;
			state.categorieList = categorieList;
			state.categorie = categorieList[0].categorie;
			state.productList = productList;
			state.product = productList[0].product;
			state.eenheidList = eenheidList;
			state.eenheid = eenheidList[0].eenheid;
			state.frequentieList = frequentieList;
			state.frequentie = this.selectFrequentie(contracts, value, categorieList[0].categorie, productList[0].product, eenheidList[0].eenheid, frequentieList)?.frequentie;
			return state;
		});
	};

	calculateWet = (categorie = this.state.categorie) => {
		return this.state.categorieList.find(e => e.categorie === categorie)?.wet ?? null;
	};

	updateCategorie = (contracts, gemeente_code) => {
		const categorieList = contracts
			.map(row => ({
				gemeente_code: row.gemeente_code,
				categorie: row.categorie,
				label: row.categorie_label,
				wet: row.wet,
			}))
			.filter(row => row.gemeente_code === gemeente_code);

		return removeDuplicates(categorieList, 'categorie');
	};

	handleCategorie = categorie => {
		const {
			contracts,
			gemeente_code,
			product: oldProduct,
			eenheid: oldEenheid,
		} = this.state;
		const productList = this.updateProduct(contracts, gemeente_code, categorie);
		const product = productList.find(r => r.product === oldProduct) ? oldProduct : productList[0]?.product;
		const eenheidList = this.updateEenheid(contracts, gemeente_code, categorie, product);
		const eenheid = eenheidList.find(r => r.eenheid === oldEenheid) ? oldEenheid : eenheidList[0]?.eenheid;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = this.selectFrequentie(contracts, gemeente_code, categorie, product, eenheid, frequentieList)?.frequentie;
		const wet = this.calculateWet(categorie);

		this.setState({
			categorie,
			productList,
			product,
			aspecifiek: product === 'Aspecifiek' || categorie === 'Generiek JW' || categorie === 'Generiek WMO',
			generic: categorie === 'Generiek JW' || categorie === 'Generiek WMO',
			eenheidList,
			eenheid,
			frequentieList,
			frequentie,
			wet: categorie === 'Generiek JW' ? 'jw' : categorie === 'Generiek WMO' ? 'wmo' : this.calculateWet(categorie),
		});
		this.handleCheckDuplicateProduct(this.state.product, categorie, this.state.begindatum, this.state.einddatum, wet);
	};

	updateProduct = (contracts, gemeente_code, categorie) => {
		const productList = contracts
			.map(row => ({
				gemeente_code: row.gemeente_code,
				categorie: row.categorie,
				product: row.product,
				label: row.product_label,
			}))
			.filter(row => row.gemeente_code === gemeente_code && row.categorie === categorie);

		return removeDuplicates(productList, 'product');
	};

	handleProduct = product => {
		const {contracts, gemeente_code, categorie, eenheid: oldEenheid} = this.state;
		const eenheidList = this.updateEenheid(contracts, gemeente_code, categorie, product);
		const eenheid = eenheidList.find(r => r.eenheid === oldEenheid) ? oldEenheid : eenheidList[0]?.eenheid;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = this.selectFrequentie(contracts, gemeente_code, categorie, product, eenheid, frequentieList)?.frequentie;

		this.setState({
			product,
			aspecifiek: product === 'Aspecifiek',
			eenheidList,
			eenheid,
			frequentieList,
			frequentie,
		});

		this.handleCheckDuplicateProduct(product, this.state.categorie, this.state.begindatum, this.state.einddatum, this.state.wet);
	};

	updateEenheid = (contracts, gemeente_code, categorie, product) => {
		if (product === 'Aspecifiek') {
			return [{eenheid: '', label: ''}];
		}
		const eenheidList = contracts
			.map(row => ({
				gemeente_code: row.gemeente_code,
				categorie: row.categorie,
				product: row.product,
				eenheid: row.eenheid,
				label: row.eenheid_label,
			}))
			.filter(
				row => row.gemeente_code === gemeente_code && row.categorie === categorie && row.product === product,
			);

		return removeDuplicates(eenheidList, 'eenheid');
	};

	handleEenheid = eenheid => {
		const {contracts, gemeente_code, categorie, product} = this.state;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = this.selectFrequentie(contracts, gemeente_code, categorie, product, eenheid, frequentieList)?.frequentie;

		this.setState({
			eenheid,
			frequentieList,
			frequentie,
		});
	};

	updateFrequentie = (contracts, gemeente_code, categorie, product, eenheid) => {
		// JZ757 is ok for jw and wmo
		let frequentieList = this.props.form.data.lists.JZ757.map(row => {
			return {frequentie: row.code, label: row.betekenis};
		});

		// Buisness logic: CD081: Frequentie, Indien eenheid de waarde 14, 16, 83 of 84 heeft en Einddatum van het ToegewezenProduct groter dan 31-12-2020 of leeg is, dan Frequentie vullen met de waarde 2 , 4 of 6
		if (
			(eenheid === '14' || eenheid === '16' || eenheid === '83' || eenheid === '84') &&
					(new Date(this.state.einddatum) > new Date('2020-12-31') || this.state.einddatum === '')
		) {
			frequentieList = frequentieList.filter(row => {
				return Number(row.frequentie) === 2 || Number(row.frequentie) === 4 || Number(row.frequentie) === 6;
			});
		}

		return frequentieList;
	};

	selectFrequentie = (contracts, gemeente_code, categorie, product, eenheid, frequencties) => {
		const frequentieList = contracts
			.map(row => ({
				gemeente_code: row.gemeente_code,
				categorie: row.categorie,
				product: row.product,
				eenheid: row.eenheid,
				label: row.eenheid_label,
				frequentie: row.frequentie,
			}))
			.filter(
				row => row.gemeente_code === gemeente_code && row.categorie === categorie && row.product === product && row.eenheid === eenheid,
			);

		return frequencties.find(e => frequentieList.find(f => f.frequentie === e.frequentie)) || frequencties[0];
	};

	handleFrequentie = value => {
		const frequentie = value;

		this.setState({
			frequentie,
		});
	};

	handleBegindatum = value => {
		this.setState(() => ({
			begindatum: value,
		}));
		this.handleCheckDuplicateProduct(this.state.product, this.state.categorie, value, this.state.einddatum, this.state.wet);
	};

	handleEinddatum = einddatum => {
		const {contracts, gemeente_code, categorie, product, eenheid, frequentie: oldFrequentie} = this.state;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = (frequentieList.find(r => Number(r.frequentie, 10) === Number(oldFrequentie, 10))
					?? frequentieList[0]
		)?.frequentie;
		this.setState({
			frequentieList,
			frequentie,
			einddatum,
		});
		this.handleCheckDuplicateProduct(this.state.product, this.state.categorie, this.state.begindatum, einddatum, this.state.wet);
	};

	handlePreSubmit = next => {
		this.setState({
			next: next,
		});
	};

	handleKeyPress = event => {
		const char = event.charCode;
		if (char >= 48 && char <= 57) {
			return true;
		}
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

	handleCheckDuplicateProduct = async (product, category, begindatum, einddatum, wet) => {
		if (product === '' || product === null || begindatum === '' || begindatum === null) return;

		const {aanbieder, token} = this.props.dbUser;
		const client_id = this.props.form.data.client.id;
		const votid = hasProp(this.props.form.data, 'id') ? this.props.form.data.id : null;
		const prod = product === 'Aspecifiek' || product === undefined ? null : product;
		const cate = category === 'Generiek JW' || category === 'Generiek WMO' ? null : category;
		const checkDuplicateProduct = await api.checkDuplicateProduct(
			aanbieder,
			client_id,
			prod,
			cate,
			begindatum,
			!einddatum ? FILTER_DATE_MAX : einddatum,
			votid,
			wet,
			api.makeHeader(token),
		);
		if (checkDuplicateProduct.error && checkDuplicateProduct.error === true) {
			this.props.handleForm(checkDuplicateProduct);
			return null;
		}

		return this.setState(state => {
			state.allocationConflict = checkDuplicateProduct.response;
			return state;
		});
	};

	handleBeschikking(beschikking) {
		this.setState(state => ({
			beschikking,
			ingangsdatum: beschikking ? state.ingangsdatum : '',
		}));
	}
	handleIngangsdatum(ingangsdatum) {
		this.setState({
			ingangsdatum,
		});
	}

	render = () => {
		// console.log(`props`, this.props)

		const {visible, code_visible, categorieList, productList, eenheidList, frequentieList, allocationConflict} = this.state;
		const formid = this.props.form.formid;
		const data = this.props.form.data;
		const {dialogWidth, votCount} = data;

		var style = {
			maxWidth: dialogWidth,
		};

		let label = '';
		if (votCount <= 1) {
			label = 'Dit verzoek indienen';
		} else {
			if (formid === 'vot-toevoegen') {
				label = `Alle ${votCount} verzoeken indienen`;
			} else if (formid === 'vot-wijzigen') {
				label = `Alle ${votCount} verzoeken indienen`;
			}
		}
		const einddatum_verplicht =
					this.state.frequentie === '2' ||
					(BERICHT_VERSIE === '3.0' && this.state.frequentie === '6');

		const {autorisatie} = this.props.dbUser;

		return (
			<form onSubmit={event => this.handleSubmit(event, data.id)} style={style} ref={this.form}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>

				<input type='hidden' name='status' value={formid === 'vot-toevoegen' ? '1' : data.status} />
				<input type='hidden' name='next' value={this.state.next} />
				<input type='hidden' name='votlist' value={data.votList.join(',')} />
				<input type='hidden' name='table' value={data.votTable} />
				<input type='hidden' name='original_status' value={data.status} />
				<input type='hidden' name='original_id' value={data.id} />
				<input type='hidden' name='wet' value={this.state.wet} />

				<header>
					<h1>
						{formid === 'toewijzing'
							? 'Toewijzing'
							: this.props.form.data.readOnly
								? 'Verzoek om toewijzing'
								: formid === 'vot-wijzigen'
									? 'Verzoek om toewijzing wijzigen'
									: formid === 'vot-verwijderen'
										? 'Verzoek om toewijzing archiveren'
										: formid === 'vot-toevoegen'
											? 'Verzoek om toewijzing toevoegen'
											: null}
					</h1>
				</header>
				{formid !== 'vot-verwijderen' ? (
					<main>
						{data.retourcodes && (
							<label htmlFor='f0'>
								<span className='error-color'>
									<DisplayRetourcode retourcodes={data.retourcodes} list={this.props.retourcodes} />
								</span>
							</label>
						)}
						{data.retour_gemeente && (
							<label>
								<span className='error-color'>
									Afkeur op woonplaatsbeginsel gemeente: {data.retour_gemeente} - {data.lists.gemeentes.find(row => row.gemeente_code === data.retour_gemeente)?.gemeente_naam}
								</span>
							</label>
						)}
						<label htmlFor='f1'>
							<span>Soort verwijzer</span>
							<select
								name='soort'
								placeholder='Soort verwijzer'
								id='f1'
								value={this.state.soort}
								onChange={event => this.handleChangeSoort(event.target.value)}
								required
								autoFocus={!this.props.form.data.readOnly}
								disabled={this.props.form.data.readOnly}>
								{data.lists.COD327.map((row, id) => (
									<option key={id} value={row.code}>
										{row.betekenis}
									</option>
								))}
							</select>
						</label>
						{this.state.soort === '01' ? (
							<>
								<label htmlFor='f12' className='col2'>
									<span>Beschikkingnummer</span>
									<input
										name='beschikking'
										type='number'
										placeholder='Beschikkingnummer'
										id='f12'
										pattern='^[0-9]{1,9}$'
										min={1}
										max={999999999}
										onKeyPress={event => this.handleKeyPress(event)}
										readOnly={this.props.form.data.readOnly}
										value={this.state.beschikking}
										onChange={event => this.handleBeschikking(event.target.value)}
									/>
								</label>

								<label htmlFor='f13' className='col2'>
									<span>Ingangsdatum</span>
									<input
										name='ingangsdatum'
										type='date'
										placeholder='Ingangsdatum'
										id='f13'
										min={formatDate(new Date(), 4)}
										required={!!this.state.beschikking}
										readOnly={this.props.form.data.readOnly}
										value={this.state.ingangsdatum}
										onChange={event => this.handleIngangsdatum(event.target.value)}
									/>
								</label>
							</>
						) : (
							<>
								<input
									name='beschikking'
									type='hidden'
									value={data.beschikking ? data.beschikking : ''}
									readOnly={this.props.form.data.readOnly}
								/>
								<input
									name='ingangsdatum'
									type='hidden'
									value={data.ingangsdatum ? formatDate(data.ingangsdatum, 1) : ''}
									readOnly={this.props.form.data.readOnly}
								/>
							</>
						)}
						{visible !== 2 && code_visible ? (
							<label htmlFor='f3' className='col2'>
								<span>Zorgverlenercode</span>
								<input
									name='zorgverlenercode'
									type='text'
									placeholder='Zorgverlenercode'
									id='f3'
									value={this.state.zorgverlenercode}
									onChange={event => this.handleDisplay(event.target.value, 2)}
									onKeyPress={event => this.handleKeyPress(event)}
									pattern='^[0-9]{8,8}$'
									minLength={8}
									maxLength={8}
									required={true}
									readOnly={this.props.form.data.readOnly}
								/>

								{this.state.zorgverlenercode.length != 8 && (
									<span className='error-color'>
												De zorgverlenercode moet bestaan uit 8 tekens
									</span>
								)}
							</label>
						) : (
							<input name='zorgverlenercode' type='hidden' value={this.state.zorgverlenercode}/>
						)}
						{visible !== 1 ? (
							<label htmlFor='f2' className='col2'>
								<span>Verwijzer</span>
								<input
									name='verwijzer'
									type='text'
									placeholder='Verwijzer'
									id='f2'
									value={this.state.verwijzer}
									onChange={event => this.handleDisplay(event.target.value, 1)}
									maxLength={63}
									required={true}
									readOnly={this.props.form.data.readOnly}
								/>
							</label>
						) : (
							<input name='verwijzer' type='hidden' value={this.state.verwijzer} />
						)}
						<label className='mt2'>
							<span>Gemeente</span>
							<select
								name='gemeente_code'
								placeholder='Gemeente'
								value={this.state.gemeente_code}
								required
								onChange={event => this.handleGemeente(event.target.value)}
								disabled={this.props.form.data.readOnly}
							>
								{this.state.gemeentes.map(([group, list]) => (
									<optgroup label={group} key={group}>
										{list.map(r => (
											<option key={r.gemeente_code} value={r.gemeente_code}>
												{r.gemeente_naam}
											</option>
										))}
									</optgroup>
								))}
							</select>

							{data.retour_gemeente && data.retour_gemeente !== this.state.gemeente_code && (
								<span className='warning-color'>
									De laatste afkeur was op woonplaatsbeginsel, controleer of dit veld correct is!
								</span>
							)}
						</label>
						<label>
							<span>Categorie</span>
							<select
								name='categorie_code'
								placeholder='Categorie'
								value={this.state.categorie}
								required
								onChange={event => this.handleCategorie(event.target.value)}
								disabled={this.props.form.data.readOnly}
							>
								{BERICHT_VERSIE !== '3.0' && (
									<>
										<option value='Generiek WMO'>
													Generiek WMO
										</option>
										<option value='Generiek JW'>
													Generiek JW
										</option>
									</>
								)}
								{categorieList.map(row => (
									<option key={row.categorie} value={row.categorie}>
										{row.label}
									</option>
								))}
							</select>
						</label>
						{!this.state.generic && (
							<label htmlFor='f6'>
								<span>Product</span>
								<select
									name='product_code'
									placeholder='Product'
									id='f6'
									value={this.state.product}
									required
									onChange={event => this.handleProduct(event.target.value)}
									disabled={this.props.form.data.readOnly}
								>
									<option value='Aspecifiek'>
												Aspecifiek
									</option>
									<option disabled></option>
									{productList.map(row => (
										<option key={row.product} value={row.product}>
											{row.label}
										</option>
									))}
									{!productList.find(row => row.product === this.state.product) &&
												this.state.product !== 'Aspecifiek' && (
										<option value={this.state.product} disabled>
														Onbekend product: {this.state.product}
										</option>
									)}
								</select>
								{allocationConflict && (
									<span className='error-color'>
										{allocationConflict}
									</span>
								)}
							</label>
						)}
						{!this.state.aspecifiek && !this.state.generic ? (
							<>
								<label htmlFor='f9' className='col4'>
									<span>Volume</span>
									<input
										name='volume'
										type='number'
										placeholder='Volume'
										id='f9'
										defaultValue={data.volume ? data.volume : ''}
										pattern='^[0-9]{1,8}$'
										required
										min={1}
										max={99999999}
										readOnly={this.props.form.data.readOnly}
									/>
								</label>

								<label htmlFor='f10' className='col4'>
									<span>Eenheid</span>
									<select
										name='eenheid'
										placeholder='Eenheid'
										id='f10'
										value={this.state.eenheid}
										required
										onChange={event => this.handleEenheid(event.target.value)}
										disabled={this.props.form.data.readOnly}>
										{eenheidList.map(row => (
											<option key={row.eenheid} value={row.eenheid}>
												{row.label}
											</option>
										))}
									</select>
								</label>

								<label htmlFor='f11' className='col2'>
									<span>Frequentie</span>
									<select
										name='frequentie'
										placeholder='Frequentie'
										id='f11'
										value={this.state.frequentie}
										required
										onChange={event => this.handleFrequentie(event.target.value)}
										disabled={this.props.form.data.readOnly}>
										{frequentieList.map(row => (
											<option key={row.frequentie} value={row.frequentie}>
												{row.label}
											</option>
										))}
									</select>
								</label>
							</>
						) : BERICHT_VERSIE !== '3.0' ? (
							<>
								<label className='col4'>
									<span>Budget</span>
									<input
										name='budget'
										type='number'
										placeholder='Budget'
										defaultValue={data.budget ? data.budget : ''}
										pattern='^[0-9]{1,8}$'
										required
										min={1}
										max={99999999}
										readOnly={this.props.form.data.readOnly}
									/>
								</label>
								<label className='col4'/>
								<label className='col4'/>
							</>
						) : null}
						<label htmlFor='f7' className='col2 mt2'>
							<span>Begindatum</span>
							<input
								type='date'
								name='begindatum'
								placeholder='Begindatum'
								id='f7'
								value={this.state.begindatum}
								max={this.state.einddatum}
								required
								onChange={event => this.handleBegindatum(event.target.value)}
								readOnly={this.props.form.data.readOnly}
							/>
							{allocationConflict && (
								<span className='error-color'>
									{allocationConflict}
								</span>
							)}
						</label>
						<label htmlFor='f8' className='col2 mt2'>
							<span>Einddatum</span>
							<input
								type='date'
								name='einddatum'
								placeholder='Einddatum'
								id='f8'
								value={this.state.einddatum}
								min={this.state.begindatum}
								onChange={event => this.handleEinddatum(event.target.value)}
								required={einddatum_verplicht || this.state.generic}
								readOnly={this.props.form.data.readOnly}
							/>
							{allocationConflict && (
								<span className='error-color'>
									{allocationConflict}
								</span>
							)}
						</label>
						<label htmlFor='f14'>
							<span>Commentaar</span>
							<textarea
								name='commentaar'
								placeholder='Commentaar'
								id='f14'
								defaultValue={data.commentaar ? data.commentaar : ''}
								maxLength={1000}
								readOnly={this.props.form.data.readOnly}
							/>
						</label>
					</main>
				) : (
					<main>{`Weet u zeker dat u dit verzoek om toewijzing met categorie ‘${data.categorie}’ en product ‘${data.product}’ wilt archiveren?`}</main>
				)}

				<footer>
					<button
						type='button'
						className='secondary'
						onClick={this.handleCancel}
						autoFocus={this.props.form.data.readOnly}>
						{this.props.form.data.readOnly ? 'Terug' : 'Annuleer'}
					</button>
					{!this.props.form.data.readOnly &&
								isAuthorized(autorisatie, 'functie.uitvoeren') &&
								formid === 'vot-verwijderen' && (
						<button type='submit' className='default'>
										OK
						</button>
					)}
					{!this.props.form.data.readOnly &&
								isAuthorized(autorisatie, 'functie.uitvoeren') &&
								formid !== 'vot-verwijderen' && (
						<>
							<button
								disabled={!!allocationConflict}
								type='submit'
								className={allocationConflict ? 'default disabled' : 'default'}
								onClick={() =>
									this.form.current.reportValidity() &&
												this.handlePreSubmit(
													this.props.form.data.id ? 'vot-edit-next' : 'vot-toevoegen',
												)
								}>
											Bewaar{' '}
								{!this.props.form.data.id
									? ' + Nieuw verzoek'
									: this.props.form.data.pendingRejectedCount
										? ' + Pas volgende aan'
										: ''}
							</button>
							{!this.props.form.data.pendingRejected && (
								<button
									disabled={!!allocationConflict || data.locked}
									type='submit'
									className={allocationConflict ? 'default disabled' : 'default'}
									onClick={() =>
										this.form.current.reportValidity() && this.handlePreSubmit('vot-verzenden')
									}>
									{label}
								</button>
							)}
						</>
					)}
				</footer>
			</form>
		);
	};
}
