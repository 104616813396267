// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {Ref} from 'react';
import formatDate from '../../util/formatDate';
import removeDuplicates from '../../util/removeDuplicates';
import api from '../../util/apiRequest';
import hasProp from '../../util/hasProp';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import sortArray from '../../util/sortArray';
import isAuthorized from '../../util/isAuthorized';
import {confirmAlert} from 'react-confirm-alert';

const TODAY = formatDate(new Date().toISOString(), 1);
const TODAY_PLUS_ONE = formatDate((() => {
	const date = new Date();
	date.setDate(date.getDate() + 1);
	return date;
})().toISOString(), 1);

interface Contract {
	'id': number,
	'categorie': string,
	'categorie_label': string,
	'wet': 'wmo' | 'jw',
	'product': string,
	'product_label': string,
	'begindatum': string,
	'einddatum': string,
	'eenheid': string,
	'eenheid_label': string,
	'frequentie': string,
	'frequentie_label': string,
	'periode': number,
	'periode_label': string,
	'agb_code': string,
	'raamcontract': number,
	'gemeente_id': number,
	'gemeente_code': string,
	'gemeente_naam': string,
	'tarief': number,
	'tarief_begindatum': string,
	'tarief_einddatum': string,
}

interface Data {
	formid: 'vow-verwijderen' | 'vow-toevoegen' | 'vow-wijzigen',
	readOnly: boolean,
	contracts: Contract[],
	client: {
		gemeente_id: string,
	}
	reden: number | undefined,
	lists: {
		JZ757: {

		}[],
		gemeentes: {
			gemeente_code: string,
			gemeente_naam: string
		}[],
	}
	dialogWidth: number | string,
	contract_regel_id?: number
	id: string
	contracts: Contract[],
	eenheid: string,
	eenheid_label: string,
	result: string,
	datum: string,
	einddatum: string,
	categorie: string
	categorie_label: string
	product_code: string
	product_name: string,
	volume: number

}
interface Toewijzing {
	id: number,
	gemeente_id: number // This is the code of the gemeente, not the id..
	categorie: null | string,
	product: null | string,
	periode: string
	begindatum: string
	einddatum: string
	start_datum: string
	stop_datum: string
	verantwoording_buiten_305_307: number
	eenheid: string
	frequentie: string
	wet: 'jw' | 'wmo',
	/**
	 * 1: Ongewijzigd,
	 * 2: Gewijzigd
	 * 3: Nieuw
	 */
	soort: 1 | 2 | 3
}

interface State {
	next: string,
	valid: boolean,
	stage: 2 | 1 | 3,
	reden: number,
	tables: {
		1: {
			name: string,
			figure: string,
			column: string,
			label: string,
			order: boolean,
			select: [],
			hint: boolean,
			hidden: boolean,
			left_hint: boolean
		}
	},
	toewijzingen: Toewijzing[],
	toewijzingen_origineel: Toewijzing[],
	toewijzing: Toewijzing | null,
	loaded: boolean,
	data: Data,
	mode: boolean,
	ingangsDatumIsBeginsDatum: boolean,
	gemeentes: [],
	contracts: Contract[],
	gemeente_code: string,
	categorie: string,
	eenheid: string,
	frequentie: string,
	/**
	 * 1: Ongewijzigd,
	 * 2: Gewijzigd
	 * 3: Nieuw
	 */
	soort: 1 | 2 | 3
	volume: number,
	budget: number,
	product: string,
	categorieList: {
		gemeente_code: string,
		categorie: string,
		label: string,
		wet: Toewijzing['wet'],
	}[],
	productList: {
		gemeente_code: string,
		product: string,
		label: string,
		wet: Toewijzing['wet'],
	}[],
	eenheidList: {
		gemeente_code: string,
		eenheid: string,
		label: string,
		wet: Toewijzing['wet'],
	}[],
	frequentieList: {
		gemeente_code: string,
		frequentie: string,
		label: string,
		wet: Toewijzing['wet'],
	}[],
	einddatum: string,
	ingangsdatum: string,
	begindatum: string,
	verwijzer: string,
	zorgverlenercode: string,
	code_visible: boolean,
	visible: number,
	generiek: boolean,
	aspecifiek: boolean
}

interface Props {
	form: {
		formid: 'vot-wijzigen' | 'vot-toevoegen' | 'vot-verwijderen'
		data: Data
	},
	client: {
		id: number
		aanbieder_id: number
	},
	dbUser: {
		token: string,
		autorisatie: unknown
	},
	handleForm: (arg: unknown) => void,
	handleVowTable: (arg: unknown) => void,
	periods: {
		type: number
		begindatum: string
		einddatum: string
	}[],
	retourcodes: unknown,
}

export default class FormChangeRequest extends React.Component<Props, State> {
	private form: Ref<HTMLFormElement>;
	private ingangsDatumRef: Ref<HTMLInputElement>;
	constructor(props: Props) {
		super(props);

		this.form = React.createRef();
		this.ingangsDatumRef = React.createRef();
		this.state = {
			next: '',
			valid:
				props.form.data.formid === 'vow-verwijderen'
					? true
					: props.form.data.reden !== undefined
						? true
						: false,
			stage: props.form.data.readOnly ? 2 : 1,
			reden: props.form.data.reden !== undefined ? Number(props.form.data.reden) : 0,
			tables: {
				1: {
					name: 'Toewijzingen',
					figure: 'alloc-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			toewijzingen: [],
			toewijzingen_origineel: [],
			toewijzing: null,
			loaded: false,
			data: props.form.data,
			mode: false,
			einddatum: '',
			ingangsDatumIsBeginsDatum: false,
			gemeentes: [],
			contracts: props.form.data.contracts,
			gemeente_code: '',
			categorie: '',
			eenheid: '',
			frequentie: '',
			soort: 0,
			volume: 0,
			budget: 0,
			product: '',
			categorieList: [],
			productList: [],
			eenheidList: [],
			frequentieList: [],
			ingangsdatum: '',
			begindatum: '',
			verwijzer: '',
			zorgverlenercode: '',
			code_visible: false,
			visible: 0,
			aspecifiek: false,
			generiek: false,
		};
	}

	handleInitForm = (toewijzing, stage) => {
		const {contracts, client} = this.state.data;
		const gemeente_code = client.gemeente_id;

		let categorieList = this.updateCategorie(contracts, gemeente_code);
		let productList =
			categorieList.length > 0 ? this.updateProduct(contracts, gemeente_code, categorieList[0].categorie) : [];
		let eenheidList =
			productList.length > 0
				? this.updateEenheid(contracts, gemeente_code, categorieList[0].categorie, productList[0].product)
				: [];
		let frequentieList =
			eenheidList.length > 0
				? this.updateFrequentie(
					contracts,
					gemeente_code,
					categorieList[0].categorie,
					productList[0].product,
					eenheidList[0].eenheid,
				)
				: [];
		const gemeentes = this.updateGemeente(contracts, toewijzing?.wet);
		if (toewijzing === null) {
			return this.setState(() => ({
				gemeentes,
				gemeente_code: gemeente_code,
				contracts: contracts,
				categorie: categorieList[0]?.categorie,
				categorieList: categorieList,
				product: productList[0]?.product,
				productList: productList,
				eenheid: eenheidList[0]?.eenheid,
				eenheidList: eenheidList,
				frequentie: frequentieList[0]?.frequentie,
				frequentieList: frequentieList,
				begindatum: '',
				einddatum: '',
				aspecifiek: false,
				generiek: false,
				toewijzing: null,
				stage: stage,
				mode: true,
				volume: 0,
				budget: 0,
				ingangsdatum: '',
				soort: 3,
				ingangsDatumIsBeginsDatum: false,
			}));
		}

		categorieList = this.updateCategorie(contracts, gemeente_code);
		const categorie =
			toewijzing.categorie === null || toewijzing.categorie === 'Generiek'
				? 'Generiek'
				: notEmpty(toewijzing.categorie)
					? toewijzing.categorie
					: categorieList[0].categorie;
		productList = this.updateProduct(contracts, gemeente_code, categorie);
		const product =
			toewijzing.product === null || toewijzing.product === 'Aspecifiek'
				? 'Aspecifiek'
				: notEmpty(toewijzing.product)
					? toewijzing.product
					: productList[0].product;
		eenheidList = this.updateEenheid(contracts, gemeente_code, categorie, product);
		const eenheid = notEmpty(toewijzing.eenheid) ? toewijzing.eenheid : eenheidList[0].eenheid;
		frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = notEmpty(toewijzing.frequentie) ? toewijzing.frequentie : frequentieList[0].frequentie;

		return this.setState(() => ({
			gemeentes,
			gemeente_code: gemeente_code,
			contracts: contracts,
			categorie: categorie,
			categorieList: categorieList,
			product: product,
			productList: productList,
			eenheid: eenheid,
			eenheidList: eenheidList,
			frequentie: frequentie,
			frequentieList: frequentieList,
			begindatum: notEmpty(toewijzing.begindatum) ? formatDate(toewijzing.begindatum, 1) : '',
			einddatum: notEmpty(toewijzing.einddatum) ? formatDate(toewijzing.einddatum, 1) : '',
			volume: notEmpty(toewijzing.volume) ? toewijzing.volume : '',
			aspecifiek: toewijzing.product === null || toewijzing.product === 'Aspecifiek' ? true : false,
			generiek: toewijzing.categorie === null || toewijzing.categorie === 'Generiek' ? true : false,
			toewijzing: toewijzing,
			stage: stage,
			mode: false,
			budget: notEmpty(toewijzing.budget) ? toewijzing.budget : '',
			ingangsdatum: notEmpty(toewijzing.ingangsdatum) ? toewijzing.ingangsdatum : '',
			ingangsDatumIsBeginsDatum: toewijzing.begindatum === toewijzing.ingangsdatum && toewijzing.begindatum < TODAY,
		}));
	};

	componentDidMount = async () => {
		const {client, dbUser} = this.props;
		const {loaded, data} = this.state;
		const {aanbieder, token} = dbUser;

		const vow_id = hasProp(data, 'id') ? data.id : '0';

		if (!loaded) {
			const getActieveToewijzing = await api.getActieveToewijzing(
				aanbieder,
				client.id,
				vow_id,
				api.makeHeader(token),
			);
			if (getActieveToewijzing.error && getActieveToewijzing.error === true) {
				this.props.handleForm(getActieveToewijzing);
				return null;
			}

			this.setState(() => ({
				toewijzingen_origineel: getActieveToewijzing.response['original'],
				toewijzingen: getActieveToewijzing.response['current'],
				loaded: true,
			}));
		}

		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	handleDisplay = (value, field) => {
		this.setState(state => {
			if (field === 1) {
				state.verwijzer = value;
			} else {
				state.zorgverlenercode = value;
			}

			if (state.code_visible === false) {
				state.visible = 0;
			} else {
				if (state.verwijzer === '' && state.zorgverlenercode === '') {
					state.visible = 3;
				} else if (state.verwijzer !== '' && state.zorgverlenercode === '') {
					state.visible = 2;
				} else if (state.verwijzer === '' && state.zorgverlenercode !== '') {
					state.visible = 1;
				} else {
					state.visible = 0;
				}
			}
			return state;
		});
	};

	handleCancel = () => {
		this.props.handleForm({});
	};

	handleSubmit = event => {
		event.preventDefault();
		event.stopPropagation();
	};

	handleChangeVolume = value => {
		this.setState(() => ({
			volume: value,
		}));
	};

	handleChangeBudget = value => {
		this.setState(() => ({
			budget: value,
		}));
	};

	handleGemeente = value => {
		const {contracts} = this.state;
		const categorieList = this.updateCategorie(contracts, value);
		const productList =
			categorieList.length > 0 ? this.updateProduct(contracts, value, categorieList[0].categorie) : [];
		const eenheidList =
			productList.length > 0
				? this.updateEenheid(contracts, value, categorieList[0].categorie, productList[0].product)
				: [];
		const frequentieList =
			eenheidList.length > 0
				? this.updateFrequentie(
					contracts,
					value,
					categorieList[0].categorie,
					productList[0].product,
					eenheidList[0].eenheid,
				)
				: [];

		this.setState(() => ({
			gemeente_code: value,
			categorieList: categorieList,
			categorie: categorieList[0]?.categorie ?? '',
			productList: productList,
			product: productList[0]?.product ?? '',
			eenheidList: eenheidList,
			eenheid: eenheidList[0]?.eenheid ?? '',
			frequentieList: frequentieList,
			frequentie: frequentieList[0]?.frequentie ?? '',
		}));
	};

	updateCategorie = (contracts: Contract[], gemeente_code: string) => {
		let categorieList = contracts
			.map(row => {
				return {
					gemeente_code: row.gemeente_code,
					categorie: row.categorie,
					label: row.categorie_label,
					wet: row.wet,
				};
			})
			.filter(row => {
				return row.gemeente_code === gemeente_code && row.wet === this.state.toewijzingen[0]?.wet;
			});
		categorieList = removeDuplicates(categorieList, 'categorie');

		return categorieList;
	};

	getCategorieLabel = categorie => {
		if (categorie === null || categorie === 'Generiek') return null;
		return (
			this.state.categorieList.find(row => {
				return categorie === row.categorie;
			})?.label ?? categorie
		);
	};

	updateGemeente = (contracts) => {
		const keys = ['Gekoppeld aan client',  'Geadviseerd door afwijzing op woonplaats beginsel', 'Laatst ingediend', 'Overige gemeentes'];
		const groupedBy = {};
		for (const row of this.props.form.data.lists.gemeentes) {
			let type;
			if (row.gemeente_code === this.props.client.gemeente_code) {
				type = 'Gekoppeld aan client';
			} else if (row.gemeente_code === this.props.form.data.retour_gemeente) {
				type = 'Geadviseerd door afwijzing op woonplaats beginsel';
			} else if (row.gemeente_code === this.props.form.data.gemeente_code) {
				type = 'Laatst ingediend';
			} else {
				type = 'Overige gemeentes';
			}
			if (!groupedBy[type]) {
				groupedBy[type] = [];
			}
			groupedBy[type].push({
				...row,
				disabled: !contracts.find(r => r.gemeente_code === row.gemeente_code),
			});
		}
		const batched = [];
		for (const key of keys) {
			if (groupedBy[key]) {
				batched.push([key, groupedBy[key]]);
			}
		}
		return batched;
	};

	handleCategorie = categorie => {
		const {
			contracts,
			gemeente_code,
			product: oldProduct,
			eenheid: oldEenheid,
			frequentie: oldFrequentie,
		} = this.state;
		const productList = this.updateProduct(contracts, gemeente_code, categorie);
		const product = productList.find(r => r.product === oldProduct) ? oldProduct : productList[0]?.product;
		const eenheidList = this.updateEenheid(contracts, gemeente_code, categorie, product);
		const eenheid = eenheidList.find(r => r.eenheid === oldEenheid) ? oldEenheid : eenheidList[0]?.eenheid;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = frequentieList.find(r => Number(r.frequentie, 10) === oldFrequentie)
			? oldFrequentie
			: frequentieList[0]?.frequentie;

		this.setState({
			categorie,
			productList,
			product,
			aspecifiek: product === 'Aspecifiek',
			generiek: categorie !== 'Generiek' ? false : true,
			eenheidList,
			eenheid,
			frequentieList,
			frequentie,
		});
	};

	updateProduct = (contracts: Contract[], gemeente_code: string, categorie: string) => {
		let productList = contracts.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				categorie: row.categorie,
				product: row.product,
				label: row.product_label,
				wet: row.wet,
			};
		});

		if (categorie === 'Generiek') {
			productList = productList.filter(row => {
				return (
					row.gemeente_code === gemeente_code &&
					row.categorie === categorie &&
					row.wet === this.state.toewijzingen[0]?.wet
				);
			});
		}


		productList = removeDuplicates(productList, 'product');
		return productList;
	};

	handleProduct = product => {
		const {contracts, gemeente_code, categorie, eenheid: oldEenheid, frequentie: oldFrequentie} = this.state;
		const eenheidList = this.updateEenheid(contracts, gemeente_code, categorie, product);
		const eenheid = eenheidList.find(r => r.eenheid === oldEenheid) ? oldEenheid : eenheidList[0]?.eenheid;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = frequentieList.find(r => Number(r.frequentie, 10) === oldFrequentie)
			? oldFrequentie
			: frequentieList[0]?.frequentie;

		this.setState({
			product,
			aspecifiek: product === 'Aspecifiek',
			generiek: categorie === 'Generiek' ? true : false,
			eenheidList,
			eenheid,
			frequentieList,
			frequentie,
		});

		this.handleCheckDuplicateProduct(product, this.state.begindatum);
	};

	getProductLabel = product => {
		if (product === null || product === 'Aspecifiek' || product === 'Generiek') return null;
		return (
			this.state.productList.find(row => {
				return product === row.product;
			})?.label ?? product
		);
	};

	updateEenheid = (contracts, gemeente_code, categorie, product) => {
		let eenheidList = [{eenheid: '', label: ''}];
		if (product !== 'Aspecifiek') {
			if (categorie !== 'Generiek') {
				eenheidList = contracts
					.map(row => {
						return {
							gemeente_code: row.gemeente_code,
							categorie: row.categorie,
							product: row.product,
							eenheid: row.eenheid,
							label: row.eenheid_label,
							wet: row.wet,
						};
					})
					.filter(row => {
						return (
							row.gemeente_code === gemeente_code &&
							row.categorie === categorie &&
							row.product === product &&
							row.wet === this.state.toewijzingen[0]?.wet
						);
					});
				eenheidList = removeDuplicates(eenheidList, 'eenheid');
			} else {
				eenheidList = contracts
					.map(row => {
						return {
							gemeente_code: row.gemeente_code,
							product: row.product,
							eenheid: row.eenheid,
							label: row.eenheid_label,
							wet: row.wet,
						};
					})
					.filter(row => {
						return (
							row.gemeente_code === gemeente_code &&
							row.product === product &&
							row.wet === this.state.toewijzingen[0]?.wet
						);
					});
				eenheidList = removeDuplicates(eenheidList, 'eenheid');
			}
		}

		return eenheidList;
	};

	handleEenheid = (eenheid: string) => {
		const {contracts, gemeente_code, categorie, product, frequentie: oldFrequentie} = this.state;
		const frequentieList = this.updateFrequentie(contracts, gemeente_code, categorie, product, eenheid);
		const frequentie = frequentieList.find(r => Number(r.frequentie, 10) === oldFrequentie)
			? oldFrequentie
			: frequentieList[0]?.frequentie;

		this.setState({
			eenheid,
			frequentieList,
			frequentie,
		});
	};

	getEenheidLabel = (eenheid: string) => {
		if (eenheid === null) return null;
		return this.state.eenheidList.find(row => {
			return eenheid === row.eenheid;
		}).label;
	};

	updateFrequentie = (contracts: Contract[], gemeente_code: string, categorie: string, product: string, eenheid: string) => {
		// JZ757 is ok for jw and wmo
		let frequentieList = this.props.form.data.lists.JZ757.map(row => {
			return {frequentie: row.code, label: row.betekenis};
		});

		if (
			(eenheid === '14' || eenheid === '16' || eenheid === '83' || eenheid === '84') &&
			(new Date(this.state.einddatum) > new Date('2020-12-31') || this.state.einddatum === '')
		) {
			frequentieList = frequentieList.filter(row => {
				return Number(row.frequentie) === 2 || Number(row.frequentie) === 4 || Number(row.frequentie) === 6;
			});
		}

		return frequentieList;
	};

	handleFrequentie = value => {
		this.setState(() => ({
			frequentie: value,
		}));
	};

	getFrequentieLabel = frequentie => {
		if (frequentie === null) return null;
		return this.state.frequentieList.find(row => {
			return Number(frequentie) === Number(row.frequentie);
		}).label;
	};

	handleIngangsdatum = value => {
		this.setState(() => ({
			ingangsdatum: value,
		}));
	};

	handleIngangsdatumIsBegindatum = () => {
		this.setState(state => ({
			ingangsdatum: state.ingangsDatumIsBeginsDatum ? TODAY : state.begindatum,
			ingangsDatumIsBeginsDatum: !state.ingangsDatumIsBeginsDatum,
		}), () => {
			if (!this.state.ingangsDatumIsBeginsDatum) {
				this.ingangsDatumRef.current.focus();
			}
		});

	};

	handleBegindatum = value => {
		this.setState(state => ({
			begindatum: value,
			ingangsdatum: state.ingangsDatumIsBeginsDatum ? value : state.ingangsdatum,
			ingangsDatumIsBeginsDatum: state.begindatum < TODAY,
		}));
		this.handleCheckDuplicateProduct(this.state.product, value);
	};

	handleEinddatum = value => {
		this.setState(() => ({
			einddatum: value,
		}));
	};

	handleKeyPress = event => {
		const char = event.charCode;
		if (char >= 48 && char <= 57) {
			return true;
		}
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

	handleCheckDuplicateProduct = async (product, begindatum) => {
		if (product === '' || product === null || begindatum === '' || begindatum === null) return false;

		const {aanbieder, token} = this.props.dbUser;
		const client_id = this.props.form.data.client.id;
		const vowid = hasProp(this.props.form.data, 'id') ? this.props.form.data.id : 0;
		const prod = product === 'Aspecifiek' ? null : product;
		const checkDuplicateProduct = await api.checkDuplicateProduct(
			aanbieder,
			client_id,
			prod,
			begindatum,
			vowid,
			api.makeHeader(token),
		);
		if (checkDuplicateProduct.error && checkDuplicateProduct.error === true) {
			this.props.handleForm(checkDuplicateProduct);
			return null;
		}
		this.setState(() => ({
			valid: checkDuplicateProduct.response,
		}));
	};

	handleStage = async (stage, next = '') => {
		// event.preventDefault()
		// event.stopPropagation()

		const formid = this.props.form.formid;
		const {aanbieder, token} = this.props.dbUser;
		const client_id = this.props.form.data.client.id;
		const gemeentes = this.props.form.data.lists.gemeentes;
		const {data} = this.props.form;

		setTimeout(() => {
			this.updateScroll();
		}, 500);

		const {toewijzing} = this.state;
		let {toewijzingen, toewijzingen_origineel} = this.state;

		if (next === 'save-alloc' || next === 'add-alloc') {
			// original allocation data
			let original = {};
			if (next === 'save-alloc') {
				original = toewijzingen_origineel.findIndex(row => {
					return row.id === toewijzing.toewijzing_id;
				});
				if (original !== -1) original = JSON.parse(JSON.stringify(toewijzingen_origineel[original]));
			}

			// current allocation data
			let current = {};
			let currentValues = {};
			if (next === 'save-alloc') {
				current = toewijzingen.findIndex(row => {
					return row.id === toewijzing.id;
				});
				currentValues = JSON.parse(JSON.stringify(toewijzingen[current]));
			}
			currentValues.soort = currentValues.soort === 3 ? 3 : 1;

			const fields = [
				'categorie',
				'product',
				'volume',
				'eenheid',
				'frequentie',
				'einddatum',
				'budget',
				'ingangsdatum',
			] as const;

			for (const field of fields) {
				// original value, new value
				let originalValue = hasProp(original, field) ? original[field] : '';
				let newValue = hasProp(this.state, field) ? this.state[field] : '';

				// number fields
				if (field === 'volume' || field === 'frequentie') {
					originalValue = Number(original[field]);
					newValue = this.state[field] === '' ? '' : Number(this.state[field]);
				}

				// compare
				if (
					field === 'categorie' &&
					(originalValue === null || originalValue === 'Generiek') &&
					this.state.mode === false
				) {
					// do nothing
				} else if (
					field === 'product' &&
					(originalValue === null || originalValue === 'Aspecifiek') &&
					this.state.mode === false
				) {
					// do nothing
				} else if (
					(field === 'volume' || field === 'eenheid' || field === 'frequentie') &&
					currentValues.product === null
				) {
					// do nothing
				} else if (newValue !== originalValue) {
					currentValues[field] = newValue;
					currentValues.soort = currentValues.soort === 3 ? 3 : 2;
				}

				if (field === 'categorie') {
					currentValues.categorie_label = this.getCategorieLabel(this.state[field]);
					currentValues.categorie = this.state[field] === 'Generiek' ? null : this.state[field];
				} else if (field === 'product') {
					currentValues.product_label = this.getProductLabel(this.state[field]);
					currentValues.product =
						this.state[field] === 'Generiek' || this.state[field] === 'Aspecifiek'
							? null
							: this.state[field];
				} else if (field === 'eenheid') {
					if (currentValues.product !== null)
						currentValues.eenheid_label = this.getEenheidLabel(this.state[field]);
					currentValues.eenheid = this.state[field];
				} else if (field === 'frequentie') {
					if (currentValues.product !== null)
						currentValues.frequentie_label = this.getFrequentieLabel(this.state[field]);
					currentValues.frequentie = this.state[field];
				}
			}

			if (currentValues.soort === 1) currentValues = original;

			if (currentValues.categorie === null || currentValues.product === null) {
				currentValues.volume = null;
				currentValues.eenheid = null;
				currentValues.eenheid_label = null;
				currentValues.frequentie = null;
				currentValues.frequentie_label = null;
			} else {
				currentValues.budget = null;
			}
			if (currentValues.categorie === null) {
				currentValues.product = null;
				currentValues.product_label = null;
			}

			if (next === 'add-alloc') {
				currentValues.soort = 3;
				currentValues.remove = 0;
				let count = toewijzingen.length;
				const new_id = toewijzingen.length !== 0 ? toewijzingen[count - 1].id + 1 : 1; // temporary id
				count = count + 1;
				currentValues.gemeente_code = this.state.gemeente_code;
				currentValues.gemeente_id = gemeentes.find(row => {
					return row.gemeente_code === currentValues.gemeente_code;
				});
				currentValues.gemeente_id = currentValues.gemeente_id.id;
				currentValues.id = new_id; // temporary id
				currentValues.toewijzing_id = new_id; // temporary id
				toewijzingen = [...toewijzingen, currentValues];
				toewijzingen_origineel = [...toewijzingen_origineel, currentValues];
			} else {
				currentValues.gemeente_code = this.state.gemeente_code;
				currentValues.gemeente_id = gemeentes.find(row => {
					return row.gemeente_code === currentValues.gemeente_code;
				});
				currentValues.gemeente_id = currentValues.gemeente_id.id;
				toewijzingen[current] = currentValues;
			}

			this.setState(() => ({
				next: '',
				stage: stage,
				toewijzingen_origineel: toewijzingen_origineel,
				toewijzingen: toewijzingen,
				toewijzing: currentValues,
			}));
		} else if (next === 'save-vow' || next === 'send') {
			if (!this.state.toewijzingen.find(row => row.soort !== 1)) {
				return;
			}
			const vow = {
				reden: this.state.reden,
				toewijzingen: toewijzingen,
			};
			let id;

			if (formid === 'vow-wijzigen') {
				id = data.id;
				const updateVow = await api.updateVow(aanbieder, client_id, id, vow, api.makeHeader(token));
				if (updateVow.error && updateVow.error === true) {
					this.props.handleForm(updateVow);
					return null;
				}
				if (updateVow.status === 200) {
					data.response = updateVow.response;
					data.formid = formid;
					this.props.handleForm({});
					this.props.handleVowTable(data);
				}
			}

			if (formid === 'vow-toevoegen') {
				const addVow = await api.addVow(aanbieder, client_id, vow, api.makeHeader(token));
				if (addVow.error && addVow.error === true) {
					this.props.handleForm(addVow);
					return null;
				}
				if (addVow.status === 200) {
					data.response = addVow.response;
					if (data.id) {
						// This VOW is based on an old VOW that has been rejected, so we need to archieve the old entry after we make  new entry
						// id only exist in old
						await api.archiveVow(aanbieder, client_id, data.id, api.makeHeader(token));
					}
					id = data.response.id;
					data.formid = formid;
					this.props.handleForm({});
					this.props.handleVowTable(data);
				}
			}
			if (next === 'send') {
				const sendVow = await api.sendVow(aanbieder, client_id, Number(id), api.makeHeader(token));
				if (sendVow.error && sendVow.error === true) {
					this.props.handleForm(sendVow);
					return null;
				}
				if (sendVow.status === 200) {
					data.response = sendVow.response;
					data.formid = 'vow-verzenden';
					this.props.handleForm({});
					this.props.handleVowTable(data);
				}
			}
		} else if (next === 'remove-vow') {
			const archiveVow = await api.archiveVow(aanbieder, client_id, data.id, api.makeHeader(token));
			if (archiveVow.error && archiveVow.error === true) {
				this.props.handleForm(archiveVow);
				return null;
			}
			if (archiveVow.status === 200) {
				data.response = archiveVow.response;
				data.formid = formid;
				this.props.handleForm({});
				this.props.handleVowTable(data);
			}
		} else {
			this.setState(() => ({
				stage: stage,
			}));
		}
	};

	handleChangeReden = reden => {
		this.setState(() => ({
			reden: Number(reden),
			valid: Number(reden) === 0 ? false : true,
		}));
	};

	updateScroll = () => {
		const tables = [1, 2, 3];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		let count = this.state.update;
		this.setState(() => ({
			update: count++,
			trigger: this.props.trigger,
		}));
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		const {tables} = this.state;
		try {
			if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
				tables[id].hint = true;
			} else {
				tables[id].hint = false;
			}

			if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
				tables[id].left_hint = true;
			} else {
				tables[id].left_hint = false;
			}
		} catch (e) {
			//
		}

		this.setState(() => ({
			tables: tables,
		}));
	};

	handleScrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleScrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		const {tables} = this.state;
		if (tables[table].column === col) {
			tables[table].order = !tables[table].order;
		} else {
			tables[table].order = true;
		}
		tables[table].column = col;
		tables[table].label = label;
		this.setState(() => ({
			tables: tables,
		}));
	};

	handleAdd = event => {
		event.preventDefault();
		event.stopPropagation();
		this.handleInitForm(null, 3);
	};

	handleEdit = (event, toewijzing) => {
		event.preventDefault();
		event.stopPropagation();
		this.handleInitForm(toewijzing, 3);
	};

	handleRemove = (event, toewijzing) => {
		event.preventDefault();
		event.stopPropagation();
		confirmAlert({
			title: 'Product archiveren',
			message: 'Weet u zeker dat u dit product wilt archiveren?',
			buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.doRemoveProduct(toewijzing)}],
		});
	};

	doRemoveProduct = toewijzing => {
		const {toewijzingen} = this.state;
		const current = toewijzingen.findIndex(row => {
			return row.id === toewijzing.id;
		});
		if (current !== -1) {
			toewijzingen[current].remove = 1;
			return this.setState(() => ({
				toewijzingen: toewijzingen,
			}));
		}
	};

	calculateConflict(toewijzing: Toewijzing | null): string {
		const begindatum = toewijzing?.begindatum || this.state.ingangsdatum;
		const result = this.state.toewijzingen.filter(t =>
			t.remove === 0 &&
			t.id !== toewijzing?.id && (
				(!['11', '12', '13', '14'].includes(this.state.categorie) && this.state.product !== 'Aspecifiek' && this.state.product === t.product && (
					((t.begindatum || t.ingangsdatum) >= begindatum && (t.begindatum || t.ingangsdatum) <= (this.state.einddatum || '4712-12-31')) ||
					(t.einddatum >= begindatum && t.begindatum <= (this.state.einddatum || '4712-12-31')) ||
					((t.begindatum || t.ingangsdatum) < begindatum && t.einddatum < (this.state.einddatum || '4712-12-31'))
				)) ||
				(!['11', '12', '13', '14'].includes(this.state.categorie) && (this.state.product === 'Aspecifiek' || t.product === null) && (this.state.categorie === t.categorie) && (
					((t.begindatum || t.ingangsdatum) >= begindatum && (t.begindatum || t.ingangsdatum) <= (this.state.einddatum || '4712-12-31')) ||
					(t.einddatum >= begindatum && t.begindatum <= (this.state.einddatum || '4712-12-31')) ||
					((t.begindatum || t.ingangsdatum) < begindatum && t.einddatum < (this.state.einddatum || '4712-12-31'))
				)) ||
				(!['11', '12', '13', '14'].includes(this.state.categorie) && (this.state.categorie === null || t.categorie === null) && (
					((t.begindatum || t.ingangsdatum) >= begindatum && (t.begindatum || t.ingangsdatum) <= (this.state.einddatum || '4712-12-31')) ||
					(t.einddatum >= begindatum && t.begindatum <= (this.state.einddatum || '4712-12-31')) ||
					((t.begindatum || t.ingangsdatum) < begindatum && t.einddatum < (this.state.einddatum || '4712-12-31'))
				))
			),
		);
		console.log(begindatum, result);

		return result.length > 0 ? `Dit verzoek conflicteert met de volgende verzoeken: ${result.map(e => `${e.categorie ? `${e.categorie_label}/${e.product_label ?? 'Aspecifiek'}` : 'Generiek ' + e.wet} van ${formatDate(e.begindatum ?? e.ingangsdatum, 2)}${e.einddatum ? ` tot ${formatDate(e.einddatum, 2)}` : ''}`).join(', ')}` : '';

		//        SELECT
		//            begindatum,
		//            einddatum,
		//            categorie_code,
		//            product_code,
		//            wet
		//        FROM
		//            `vot`
		//        WHERE
		//            1 = 1
		//            AND `aanbieder_id` = :aanbieder_id
		//            AND `client_id` = :client_id
		//            AND (:id IS NULL OR `id` != :id)
		//            AND `status` = :status
		//            AND `wet` = :wet
		//            AND `verwijderd` = :verwijderd
		//            AND ((
		//
		//            --  Overlap specifiek op periode
		//                :categorie_code  NOT IN("11", "12", "13", "14") AND (`product_code` = :product_code) AND (begindatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                            OR einddatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                            OR begindatum < :begindatum and einddatum < :einddatum )
		//            )OR(
		//            --  Overlap met aspecifiek op periode
		//                :categorie_code  NOT IN("11", "12", "13", "14") AND (`product_code` IS NULL OR :product_code IS NULL) AND (`categorie_code` = :categorie_code) AND (begindatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                                                OR einddatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                                                OR begindatum < :begindatum and einddatum < :einddatum )
		//            )OR(
		//            --  Overlap met generiek op periode
		//                :categorie_code  NOT IN("11", "12", "13", "14") AND  (:categorie_code IS NULL OR  `categorie_code` IS NULL) AND (begindatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                                                    OR einddatum between :begindatum and coalesce(:einddatum,date_format('4712-12-31','yyyy-mm-dd'))
		//                                                                                                                                    OR begindatum < :begindatum and einddatum < :einddatum )
		//            ))
	}

	render = () => {
		const {
			categorieList,
			productList,
			eenheidList,
			frequentieList,
			valid,
			stage,
			tables,
			toewijzingen,
			data,
		} = this.state;

		const hasChanges = this.state.toewijzingen.find(row => row.soort !== 1);

		const formid = this.props.form.formid;

		const {autorisatie} = this.props.dbUser;

		const reden = data.lists.JW758.filter(row => {
			return this.state.reden === Number(row.code);
		}).map(row => {
			return row.betekenis;
		});

		let data1 = toewijzingen.filter(row => {
			return row.remove === 0;
		});

		data1 = sortArray(data1, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		const allocationConflict = this.state.stage === 3 ? this.calculateConflict(this.state.toewijzing) : false;

		return (
			<form
				style={{maxWidth: data.dialogWidth}}
				onSubmit={event => this.handleSubmit(event)}
				ref={this.form}
				key={this.state.stage}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<header>
					<h1>
						{this.state.reden === 3
							? 'Verzoek om wijziging'
							: formid === 'vow-wijzigen'
								? this.state.reden
									? `Verzoek om wijziging: ${reden}`
									: 'Verzoek om wijziging'
								: formid === 'vow-verwijderen'
									? 'Verzoek om wijziging archiveren'
									: formid === 'vow-toevoegen'
										? this.state.reden
											? `Verzoek om wijziging: ${reden}`
											: 'Verzoek om wijziging'
										: 'Verzoek om wijziging'}
					</h1>
				</header>
				{formid !== 'vow-verwijderen' ? (
					stage === 1 ? (
						<main style={{width: '100%'}}>
							<label className='col4'></label>
							<label htmlFor='f1' className='col2'>
								<span>Reden</span>
								<select
									name='reden'
									placeholder='Reden'
									id='f1'
									value={this.state.reden}
									onChange={event => this.handleChangeReden(event.target.value)}
									required
									autoFocus>
									<option key={0} value={0}>
										Kies een reden
									</option>
									<option disabled></option>
									{data.lists.JW758.map((row, id) => (
										<option key={id} value={row.code}>
											{row.betekenis}
										</option>
									))}
								</select>
							</label>
							<label className='col4'></label>
						</main>
					) : stage === 2 ? (
						<section className='table-element table-in-form'>
							<section className='table-header'>
								<figure className={tables[1].figure}></figure>
								<h2>{tables[1].name}</h2>

								<button
									className={'button add'}
									disabled={
										this.props.form.data.readOnly || !isAuthorized(autorisatie, 'functie.uitvoeren')
									}
									onClick={event => this.handleAdd(event)}></button>
							</section>
							{data1.length !== 0 ? (
								<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
									<aside
										className={tables[1].hint === true ? 'hint visible' : 'hint'}
										onClick={() => this.handleScrollToEnd(1)}></aside>
									<aside
										className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'}
										onClick={() => this.handleScrollToStart(1)}></aside>
									<table className='data-table'>
										<tbody>
											<tr>
												<td></td>
												<td>Verzoek</td>
												<td>Nummer</td>
												<td>Categorie</td>
												<td>Product</td>
												<td>Begindatum</td>
												<td>Einddatum</td>
												<td>Volume</td>
												<td>Eenheid</td>
												<td>Frequentie</td>
												<td>Ingangsdatum</td>
												<td>Budget</td>
											</tr>

											{data1.map(row => (
												<tr key={row.id} style={noHover}>
													<td>
														<button
															className={
																this.props.form.data.readOnly
																	? 'button read'
																	: 'button edit'
															}
															disabled={!isAuthorized(autorisatie, 'functie.uitvoeren')}
															onClick={event => this.handleEdit(event, row)}></button>
														{!this.props.form.data.readOnly && (
															<button
																className='button remove'
																disabled={
																	isAuthorized(autorisatie, 'functie.uitvoeren')
																		? row.soort === 3
																			? false
																			: true
																		: true
																}
																onClick={event =>
																	this.handleRemove(event, row)
																}></button>
														)}
													</td>
													<td>
														{notEmpty(row.soort)
															? row.soort === 1
																? 'Ongewijzigd'
																: row.soort === 2
																	? 'Gewijzigd'
																	: row.soort === 3
																		? 'Nieuw'
																		: ''
															: ''}
													</td>
													<td className='align-right'>
														{notEmpty(row.toewijzing_nummer) ? row.toewijzing_nummer : ''}
													</td>

													<td>
														{notEmptyString(row.categorie)
															? row.categorie === null
																? 'Generiek'
																: row.categorie_label
															: ''}
													</td>
													<td>
														{notEmptyString(row.product)
															? row.product === null && row.categorie === null
																? 'Generiek'
																: row.product === null && row.categorie !== null
																	? 'Aspecifiek'
																	: row.product
															: ''}
													</td>
													<td>
														{notEmpty(row.begindatum) ? formatDate(row.begindatum) : ''}
													</td>
													<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum) : ''}</td>
													<td className='align-right'>
														{notEmpty(row.volume) ? row.volume : ''}
													</td>
													<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : ''}</td>
													<td>
														{notEmpty(row.frequentie_label) ? row.frequentie_label : ''}
													</td>
													<td>
														{notEmpty(row.ingangsdatum) ? formatDate(row.ingangsdatum) : ''}
													</td>
													<td>{notEmpty(row.budget) ? row.budget : ''}</td>
												</tr>
											))}
										</tbody>
									</table>
								</section>
							) : (
								<section className='table-empty'>Er zijn geen actieve toewijzingen</section>
							)}
						</section>
					) : (
						<main>
							{data.retour_gemeente && (
								<label>
									<span className='error-color'>
										Afkeur op woonplaatsbeginsel gemeente: {data.retour_gemeente} - {data.lists.gemeentes.find(row => row.gemeente_code === data.retour_gemeente)?.gemeente_naam}
									</span>
								</label>
							)}
							<label className='mt2'>
								<span>Gemeente</span>
								<select
									name='gemeente_code'
									placeholder='Gemeente'
									value={this.state.gemeente_code}
									required
									onChange={event => this.handleGemeente(event.target.value)}
									disabled={
										this.props.form.data.readOnly
											? true
											: this.state.mode
												? false
												: this.state.toewijzing.soort === 3
													? false
													: true
									}
								>
									{this.state.gemeentes.map(([group, list]) => (
										<optgroup label={group} key={group}>
											{list.map(r => (
												<option key={r.gemeente_code} value={r.gemeente_code} disabled={r.disabled}>
													{r.gemeente_naam}
												</option>
											))}
										</optgroup>
									))}
								</select>

								{data.retour_gemeente && data.retour_gemeente !== this.state.gemeente_code && (
									<span className='warning-color'>
										De laatste afkeur was op woonplaatsbeginsel, controleer of dit veld correct is!
									</span>
								)}
							</label>
							<label htmlFor='f5' className='col2'>
								<span>Categorie</span>
								<select
									name='categorie_code'
									placeholder='Categorie'
									id='f5'
									value={this.state.categorie}
									onChange={event => this.handleCategorie(event.target.value)}
									disabled={
										this.props.form.data.readOnly
											? true
											: this.state.mode
												? false
												: this.state.toewijzing.soort === 3
													? false
													: true
									}
									className={
										this.state.mode ? '' : this.state.toewijzing.soort === 3 ? '' : 'disabled'
									}>
									<option key={'Generiek'} value='Generiek'>
										Generiek
									</option>
									<option disabled></option>
									{categorieList.map(row => (
										<option key={row.categorie} value={row.categorie}>
											{row.label}
										</option>
									))}
								</select>
								{allocationConflict && (
									<span className='error-color'>
										{allocationConflict}
									</span>
								)}
							</label>
							{this.state.generiek === false && (
								<label htmlFor='f6' className='col2'>
									<span>Product</span>
									<select
										name='product_code'
										placeholder='Product'
										id='f6'
										value={this.state.product}
										disabled={
											this.props.form.data.readOnly
												? true
												: this.state.mode
													? false
													: this.state.toewijzing.soort === 3
														? false
														: true
										}
										className={
											this.state.mode ? '' : this.state.toewijzing.soort === 3 ? '' : 'disabled'
										}
										onChange={event => this.handleProduct(event.target.value)}>
										{this.state.categorie !== 'Generiek' ? (
											<React.Fragment key={'Aspecifiek1'}>
												<option key={'Aspecifiek'} value='Aspecifiek'>
													Aspecifiek
												</option>
												<option disabled></option>
											</React.Fragment>
										) : this.state.mode === false ? (
											<React.Fragment key={'Generiek1'}>
												<option key={'Generiek'} value='Generiek'>
													Generiek
												</option>
												<option disabled></option>
											</React.Fragment>
										) : (
											''
										)}
										{productList.map(row => (
											<option key={row.product} value={row.product}>
												{row.label}
											</option>
										))}
										{!productList.find(row => row.product === this.state.product) &&
											this.state.product !== 'Aspecifiek' &&
											this.state.product !== 'Generiek' && (
											<option value={this.state.product} disabled>
													Onbekend product: {this.state.product}
											</option>
										)}
									</select>
									{allocationConflict && (
										<span className='error-color'>
											{allocationConflict}
										</span>
									)}
								</label>
							)}

							{this.state.aspecifiek === false &&
								this.state.generiek === false &&
								this.state.product !== 'Generiek' && (
								<>
									<label htmlFor='f9' className='col4'>
										<span>Volume</span>
										<input
											name='volume'
											type='number'
											placeholder='Volume'
											id='f9'
											value={this.state.volume}
											onChange={event => this.handleChangeVolume(event.target.value)}
											pattern='^[0-9]{(1, 8)}$'
											required
											min={1}
											max={99999999}
											readOnly={this.props.form.data.readOnly}
										/>
									</label>

									<label htmlFor='f10' className='col4'>
										<span>Eenheid</span>
										<select
											name='eenheid'
											placeholder='Eenheid'
											id='f10'
											value={this.state.eenheid}
											required
											disabled={this.props.form.data.readOnly}
											onChange={event => this.handleEenheid(event.target.value)}>
											{eenheidList.map(row => (
												<React.Fragment key={row.eenheid}>
													<option key={row.eenheid} value={row.eenheid}>
														{row.label}
													</option>
												</React.Fragment>
											))}
										</select>
									</label>

									<label htmlFor='f11' className='col2'>
										<span>Frequentie</span>
										<select
											name='frequentie'
											placeholder='Frequentie'
											id='f11'
											value={this.state.frequentie}
											required
											disabled={this.props.form.data.readOnly}
											onChange={event => this.handleFrequentie(event.target.value)}>
											{frequentieList.map(row => (
												<option key={row.frequentie} value={row.frequentie}>
													{row.label}
												</option>
											))}
										</select>
									</label>
								</>
							)}

							{this.state.mode === false && this.state.toewijzing?.soort !== 3 && (
								<label htmlFor='f7' className='col2'>
									<span>Begindatum</span>
									<input
										type='date'
										name='begindatum'
										placeholder='Begindatum'
										id='f7'
										value={this.state.begindatum}
										max={this.state.einddatum}
										disabled={!!this.state.begindatum}
										className='disabled'
										readOnly={this.props.form.data.readOnly}
										onChange={event => this.handleBegindatum(event.target.value)}
									/>
									{allocationConflict && (
										<span className='error-color'>
											{allocationConflict}
										</span>
									)}
								</label>
							)}
							{(this.state.aspecifiek || this.state.generiek) && (
								<label htmlFor='f12' className='col2'>
									<span>Budget</span>
									<input
										name='budget'
										type='number'
										placeholder='Budget'
										id='f12'
										value={this.state.budget}
										readOnly={this.props.form.data.readOnly}
										onChange={event => this.handleChangeBudget(event.target.value)}
										pattern='^[0-9]{(1, 8)}$'
										required
										min={1}
										max={99999999}
									/>
								</label>
							)}
							<span className='col2'>
								<label htmlFor='f13' style={{ padding: 0 }}>
									<span>Gewenste ingangsdatum</span>
									<input
										type='date'
										name='ingangsdatum'
										placeholder='Ingangsdatum'
										id='f13'
										value={this.state.ingangsdatum}
										readOnly={this.props.form.data.readOnly}
										onChange={event => this.handleIngangsdatum(event.target.value)}
										min={TODAY_PLUS_ONE}
										required
										ref={this.ingangsDatumRef}
										disabled={this.state.ingangsDatumIsBeginsDatum}
									/>
								</label>
								{TODAY > this.state.begindatum && this.state.begindatum && this.state.mode !== true && (
									<label>
										<input
											type='checkbox'
											disabled={this.props.form.data.readOnly}
											checked={this.state.ingangsDatumIsBeginsDatum}
											onChange={() => this.handleIngangsdatumIsBegindatum()}
											style={{width: 'initial', marginRight: 4, verticalAlign: 'middle'}}
										/>
										Ingangsdatum is de begindatum
									</label>
								)}
							</span>
							<label className='col2'>
								<span>Einddatum</span>
								<input
									type='date'
									name='einddatum'
									placeholder='Einddatum'
									value={this.state.einddatum}
									min={this.state.begindatum || this.state.ingangsdatum}
									readOnly={this.props.form.data.readOnly}
									required={this.state.frequentie === '2' || this.state.frequentie === '6'}
									onChange={event => this.handleEinddatum(event.target.value)}
								/>
								{allocationConflict && (
									<span className='error-color'>
										{allocationConflict}
									</span>
								)}
							</label>
						</main>
					)
				) : (
					<main>{'Weet u zeker dat u dit verzoek om wijziging wilt archiveren?'}</main>
				)}

				<footer>
					{stage !== 3 && (
						<button type='button' className='secondary' onClick={this.handleCancel}>
							{this.props.form.data.readOnly ? 'Terug' : 'Annuleer'}
						</button>
					)}
					{formid !== 'vow-verwijderen' && stage === 1 && (
						<button type='submit' className='default' onClick={() => this.handleStage(2)} disabled={!valid}>
							Verder naar gewenste situatie
						</button>
					)}
					{stage === 2 && !this.props.form.data.readOnly && (
						<>
							<button type='submit' className='secondary' onClick={() => this.handleStage(1)}>
								Terug naar selectie reden
							</button>
							{isAuthorized(autorisatie, 'functie.uitvoeren') && (
								<button
									type='submit'
									className='default'
									onClick={() => this.handleStage(2, 'save-vow')}
									disabled={!hasChanges || allocationConflict}
									title={
										hasChanges
											? 'U kunt momenteel niet opslaan omdat er geen veranderingen zijn ten opzichte van de orginele situatie.'
											: ''
									}>
									Bewaar verzoek om wijziging
								</button>
							)}
							{isAuthorized(autorisatie, 'functie.uitvoeren') && (
								<button
									type='submit'
									className='default'
									onClick={() => this.handleStage(2, 'send')}
									disabled={
										data.locked || data1.length === 0 || data.hastoewijzing === false || !hasChanges
									}>
									Dien verzoek om wijziging in
								</button>
							)}
						</>
					)}
					{stage === 3 && (
						<>
							<button type='submit' className='default' onClick={() => this.handleStage(2)}>
								{this.props.form.data.readOnly ? 'Terug' : 'Annuleer'}
							</button>
							{!this.props.form.data.readOnly &&
								(this.state.mode === true ? (
									<button
										type='submit'
										className='default'
										disabled={!!allocationConflict}
										onClick={() =>
											this.form.current.reportValidity() && this.handleStage(2, 'add-alloc')
										}>
										Toevoegen
									</button>
								) : (
									<button
										type='submit'
										className='default'
										disabled={!!allocationConflict}
										onClick={() =>
											this.form.current.reportValidity() && this.handleStage(2, 'save-alloc')
										}>
										Opslaan
									</button>
								))}
						</>
					)}
					{isAuthorized(autorisatie, 'functie.uitvoeren') && formid === 'vow-verwijderen' && (
						<button type='submit' className='default' onClick={() => this.handleStage(2, 'remove-vow')}>
							OK
						</button>
					)}
				</footer>
			</form>
		);
	};
}
